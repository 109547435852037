import { createAsyncThunk } from '@reduxjs/toolkit';
import { ValueSet, QuestionnaireItem, QuestionnaireResponse, Questionnaire, Bundle } from 'fhir/r4';
import { DEFAULT_LANGUAGE, HN_SKJEMA_ERRORCODES_RESOURCES, HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import LocalStorageConstants from 'src/constants/localstorage';
import { getResources } from 'src/resources';
import { getLanguagesInDefinition } from 'src/util/getLanguagesInDefinition';
import { getPreferredLanguage, isValidPortalLanguage } from 'src/util/portalLanguage-utils';
import { stringToLanguageLocales } from 'src/util/stringToLanguageLocales';
import { trackFormEvent } from 'src/util/trackFormEvent';

import {
  CreateSkjemasvarRequest,
  GetSkjemaResponse,
  GetSkjemasvarResponse,
  TextMessage,
  UpdateSkjemasvarRequest,
  UpdateSkjemasvarResponse,
} from '../../types/MinHelseEntities';

import { remove } from '@helsenorge/core-framework/pending-changes/pending-changes-state';
import Languages from '@helsenorge/core-utils/constants/languages';
import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';
import { setUserLanguage } from '@helsenorge/framework-utils/hn-language';
import { HelsenorgeProxyError, erTjenester, get, post, put } from '@helsenorge/framework-utils/hn-proxy-service';
import { setAllowNavigation } from '@helsenorge/framework-utils/hn-user';
import { fetchResources } from '@helsenorge/framework-utils/resources';
import { OrgenhetHierarki, ProcedureStatusCodes, setSkjemaDefinitionAction } from '@helsenorge/refero';

import { LagreSkjemaDonePayload, SetResourcesPayload, shouldShowBeforeUnload, SkjemaDefinisjonLoadedPayload } from './actions';
import {
  createQuestionnaireResponse,
  createSavePayload,
  generateQuestionnaireResponseData,
  getLoadParameter,
  getQuestionnaireInLanguage,
  getQuestionnairs,
  gotoMinHelse,
  gotoUrl,
  isHelsenorgeProxyError,
  isNewDocument,
  redirectTilLagretSkjemaPaaDokumenter,
  removeAttachmentsIfNeeded,
  updateUrlAndFetchMessage,
} from './actionUtils';
import { getQueryParams, getSkjemaNameFromUrl, oppdaterQuestionnaireResponseSomNyReponseHvisGjenbrukes, toRelativeUrl } from '../../util';
import { FULLFORING_AV_SKJEMA_FEIL, LAGRING_AV_SKJEMA_FEIL, logSkjemaLagringsFeil } from '../../util/logUtil';
import { redirectEtterFullfortSkjema } from '../../util/veileder';
import { AppDispatch, RootState } from '../reducers';
import { ProxyErrorResponse, transformFetchErrors } from './errorHandling';

type ValueSetResponse = {
  ValueSet: { System: string; Code: string; Display: string }[];
};
type LastMottakereResponse = { Orgenhet: OrgenhetHierarki[] };

export const sendInnSkjema = createAsyncThunk<
  { samtaleGuid: string | undefined },
  {
    timeSkjemaLoadedForUser: number;
    automatiskLagring: boolean;
    withDiscretion?: boolean;
    isMicrowebStep: boolean | undefined;
    onCompleteMicroweb?: (formData: Record<string, string | undefined>) => void;
    dokumentGuidMicroweb?: string;
  },
  { state: RootState; dispatch: AppDispatch; rejectValue: string | ProxyErrorResponse }
>(
  'sendInnSkjema',
  async (
    { timeSkjemaLoadedForUser, automatiskLagring, withDiscretion, isMicrowebStep, onCompleteMicroweb, dokumentGuidMicroweb },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    const isNewDoc = isNewDocument(getState());

    const qr = JSON.stringify({
      ...createQuestionnaireResponse(getState()),
      status: ProcedureStatusCodes.COMPLETED,
    });
    try {
      const payload = createSavePayload(qr, getState(), automatiskLagring, withDiscretion, isMicrowebStep, dokumentGuidMicroweb);
      const skalMicrowebStepOverskriveDokument = isMicrowebStep && dokumentGuidMicroweb && dokumentGuidMicroweb !== '';
      const harDocumentGuidPayload = Object.prototype.hasOwnProperty.call(payload, 'DokumentGuid');

      let sendFn = harDocumentGuidPayload && !isNewDoc ? put : post;

      if (skalMicrowebStepOverskriveDokument && harDocumentGuidPayload) {
        sendFn = put;
      }

      const response = await sendFn<UpdateSkjemasvarResponse, CreateSkjemasvarRequest | UpdateSkjemasvarRequest>(
        'skjemainternal',
        'api/v1/Skjemasvar',
        payload
      );
      if (!response) return rejectWithValue('ingen respons fra server');

      if (response.SamtaleGuid && !isNewDoc) {
        return fulfillWithValue({ samtaleGuid: response.SamtaleGuid });
      } else {
        // redirect til dokumenter eller til neste steg i veileder
        const queryParams = getQueryParams();
        redirectEtterFullfortSkjema(
          getState().refero.form.FormDefinition,
          getState().refero.form.FormData,
          queryParams.redirect,
          queryParams.contextParameter,
          response.DokumentGuid,
          () => redirectTilLagretSkjemaPaaDokumenter(response.DokumentGuid),
          isMicrowebStep,
          onCompleteMicroweb
        );
        return fulfillWithValue({ samtaleGuid: undefined });
      }
    } catch (error) {
      const err = error as HelsenorgeProxyError;
      const errorInfo = transformFetchErrors(err);
      // Logger etter dispatch in case en gang logger metoden feiler så får
      // fortsatt bruker en feilmelding
      logSkjemaLagringsFeil(FULLFORING_AV_SKJEMA_FEIL, err.correlationId, err.response?.code, err.statusCode, timeSkjemaLoadedForUser);
      return rejectWithValue(errorInfo);
    }
  }
);
export const overstyrSkjemaFraMicroweb = createAsyncThunk<
  void,
  {
    currentQuestionnaire: Questionnaire;
    questionnaireResponse: QuestionnaireResponse | undefined;
  },
  {
    rejectValue: string | ProxyErrorResponse;
  }
>('skjemautfyller/overstyrSkjemaFraMicroweb', async ({ currentQuestionnaire, questionnaireResponse }, { dispatch }) => {
  dispatch(
    setSkjemaDefinitionAction({
      questionnaire: currentQuestionnaire,
      questionnaireResponse: questionnaireResponse,
      language: undefined,
      syncQuestionnaireResponse: true,
    })
  );
});

export const lagreSkjema = createAsyncThunk<
  LagreSkjemaDonePayload,
  { timeSkjemaLoadedForUser: number; automatiskLagring: boolean; dokumentGuidMicroweb?: string; isMicrowebStep?: boolean },
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string | ProxyErrorResponse;
  }
>(
  'lagreSkjema',
  async (
    { timeSkjemaLoadedForUser, automatiskLagring, dokumentGuidMicroweb, isMicrowebStep },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const globalState = getState();
      const questionnaireResponse = createQuestionnaireResponse(globalState);
      const skalMicrowebStepOverskriveDokument = isMicrowebStep && dokumentGuidMicroweb && dokumentGuidMicroweb !== '';

      oppdaterQuestionnaireResponseSomNyReponseHvisGjenbrukes(questionnaireResponse, globalState.refero.ui.userMetadata);
      const qr = JSON.stringify(questionnaireResponse);
      const payload = createSavePayload(qr, globalState, automatiskLagring, false, isMicrowebStep, dokumentGuidMicroweb);
      let lagreFn = Object.prototype.hasOwnProperty.call(payload, 'DokumentGuid') && !isNewDocument(globalState) ? put : post;

      // Merk: Microwebprosessflyt bryr seg ikke om isNewDocument sjekken, siden dokumentGuidMicroweb staten er fra prosessflyt og ikke i globalsate
      // Dette forutsetter at dokumentGuidMicroweb blir tatt vare på frem/tilbake i prosessflyten ( noe den gjør nå )
      if (skalMicrowebStepOverskriveDokument && Object.prototype.hasOwnProperty.call(payload, 'DokumentGuid')) {
        lagreFn = put;
      }

      const response = await lagreFn<UpdateSkjemasvarResponse, CreateSkjemasvarRequest | UpdateSkjemasvarRequest>(
        'skjemainternal',
        'api/v1/Skjemasvar',
        payload
      );

      if (!response) return rejectWithValue('ingen respons fra server');
      const resonseQr: QuestionnaireResponse = JSON.parse(response?.QuestionnaireResponse);
      const currentQuestionnaire =
        globalState.refero.ui.questionnaires.filter(x => `Questionnaire/${x.id}` === questionnaireResponse.questionnaire)[0] ||
        globalState.refero.ui.questionnaires[0];

      dispatch(
        setSkjemaDefinitionAction({
          questionnaire: currentQuestionnaire,
          questionnaireResponse: resonseQr,
        })
      );

      return fulfillWithValue({ dokumentGuid: response.DokumentGuid });
    } catch (error) {
      const err = error as HelsenorgeProxyError;
      const errorInfo = transformFetchErrors(err);
      if (isHelsenorgeProxyError(err)) {
        logSkjemaLagringsFeil(
          LAGRING_AV_SKJEMA_FEIL,
          err.correlationId || null,
          err.response?.code,
          err.statusCode,
          timeSkjemaLoadedForUser
        );
      }
      return rejectWithValue(errorInfo);
    }
  }
);

export const loadValueSet = async (
  searchString: string,
  item: QuestionnaireItem,
  successCallback: (valueSet: ValueSet) => void,
  errorCallback: (error: string) => void
): Promise<void> => {
  try {
    const response = await get<ValueSetResponse>('skjemainternal', 'api/v1/ValueSet/$expand', {
      url: encodeURIComponent(item.answerValueSet || ''),
      filter: encodeURIComponent(searchString),
    });
    if (!response) {
      return;
    }
    const valueSet: ValueSet = {
      resourceType: 'ValueSet' as const,
      status: 'draft',
      compose: {
        include: [
          {
            system: response.ValueSet.length > 0 ? response.ValueSet[0].System : '',
            concept: response.ValueSet.map(x => {
              return {
                code: x.Code,
                display: x.Display,
              };
            }),
          },
        ],
      },
    };
    successCallback(valueSet);
  } catch (error) {
    errorCallback(error as string);
  }
};
export const lastMottakere = async (
  skjemaTekniskNavn: string,
  successCallback: (receivers: OrgenhetHierarki[]) => void,
  errorCallback: () => void
): Promise<void> => {
  try {
    const response = await get<LastMottakereResponse>('skjemainternal', `api/v1/OrgEnhetHierarki?skjemaNavn=${skjemaTekniskNavn}`);
    if (response) {
      successCallback(response?.Orgenhet);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (_error) {
    errorCallback();
  }
};
const handleLanguageAndLoadResources = (questionnaires: Questionnaire[], dispatch: AppDispatch): Questionnaire => {
  const languages = getLanguagesInDefinition(questionnaires);
  const preferredLanguage = getPreferredLanguage(languages);
  const questionnaireInLanguage = getQuestionnaireInLanguage(questionnaires, preferredLanguage);

  const shouldLoadResources = questionnaireInLanguage && preferredLanguage.toLowerCase() !== DEFAULT_LANGUAGE.toLowerCase();

  if (shouldLoadResources) {
    dispatch(loadResources({ language: preferredLanguage }));
  } else if (
    questionnaires.length === 1 &&
    questionnaires[0].language &&
    questionnaires[0].language.toLowerCase() !== DEFAULT_LANGUAGE.toLowerCase()
  ) {
    dispatch(loadResources({ language: questionnaires[0].language }));
  }

  return questionnaireInLanguage || questionnaires[0];
};

export const fetchSkjemaoppgave = createAsyncThunk<
  SkjemaDefinisjonLoadedPayload,
  {
    skjemaTekniskNavn?: string;
    documentGuid?: string;
    isMicroweb?: boolean;
    skjemadata?: string;
  },
  { state: RootState; dispatch: AppDispatch; rejectValue: ProxyErrorResponse | string }
>(
  'fetchSkjemaoppgave',
  async ({ skjemaTekniskNavn, documentGuid, isMicroweb, skjemadata }, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const skjemaNavnParam = (isMicroweb && skjemaTekniskNavn) || getSkjemaNameFromUrl();

    const params = getQueryParams();
    const documentGuidParam = (isMicroweb && documentGuid) || params?.contextId;

    if (documentGuidParam && isAuthorized()) {
      const getParams = {
        ...(params?.taskId && { OppgaveGuid: params.taskId }),
        DokumentGuid: documentGuidParam,
      };
      try {
        const response = await get<GetSkjemasvarResponse>('skjemainternal', 'api/v1/Skjemasvar', getParams);
        if (response) {
          const {
            Metadata: metadata,
            WarningMessage: warning,
            QuestionnaireResponse: questionnaireResponse,
            Questionnaire: data,
          } = response;

          trackFormEvent('form continue', skjemaNavnParam);
          let parsedData: Bundle | Questionnaire;
          try {
            parsedData = JSON.parse(data);
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
          } catch (error) {
            return rejectWithValue('Feil i json struktur');
          }

          const questionnaires = getQuestionnairs(parsedData);

          if (!questionnaires) {
            return rejectWithValue('oppgavenFinsIkke');
          }
          const mainQuestionnaire = handleLanguageAndLoadResources(questionnaires, dispatch);

          // endre url og hent driftsmelding for dette skjema
          if (!isMicroweb) {
            updateUrlAndFetchMessage(mainQuestionnaire);
          }
          const queryParams = getQueryParams();
          const qr = generateQuestionnaireResponseData(questionnaireResponse, mainQuestionnaire, isMicroweb, skjemadata);

          removeAttachmentsIfNeeded(metadata, qr, mainQuestionnaire);

          dispatch(setSkjemaDefinitionAction({ questionnaire: mainQuestionnaire, questionnaireResponse: qr }));
          return fulfillWithValue({
            questionnaires,
            userMetadata: metadata,
            warning,
            taskId: queryParams.taskId,
            returUrl: queryParams.returURL,
            dokumentGuid: queryParams.contextId,
          });
        } else {
          return rejectWithValue('no response');
        }
      } catch (error) {
        const errorInfo = transformFetchErrors(error);

        return rejectWithValue(errorInfo);
      }
    } else if (params?.taskId && isAuthorized()) {
      const getParams = {
        ...(params?.resourceId && { Id: params.resourceId }),
        OppgaveGuid: params?.taskId,
      };
      try {
        const response = await get<GetSkjemaResponse>('skjemainternal', 'api/v1/Skjema', getParams);
        if (response) {
          const { Metadata: metadata, WarningMessage: warning, Questionnaire: data } = response;
          trackFormEvent('form continue', skjemaNavnParam);
          let parsedData: Bundle | Questionnaire;
          try {
            parsedData = JSON.parse(data);
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
          } catch (error) {
            return rejectWithValue('Feil i json struktur');
          }
          const questionnaires = getQuestionnairs(parsedData);

          if (!questionnaires) {
            return rejectWithValue('oppgavenFinsIkke');
          }
          const mainQuestionnaire = handleLanguageAndLoadResources(questionnaires, dispatch);

          // endre url og hent driftsmelding for dette skjema
          if (!isMicroweb) {
            updateUrlAndFetchMessage(mainQuestionnaire);
          }
          const queryParams = getQueryParams();
          const qr = generateQuestionnaireResponseData(undefined, mainQuestionnaire, isMicroweb, skjemadata);

          removeAttachmentsIfNeeded(metadata, qr, mainQuestionnaire);
          dispatch(setSkjemaDefinitionAction({ questionnaire: mainQuestionnaire, questionnaireResponse: qr }));
          return fulfillWithValue({
            questionnaires,
            userMetadata: metadata,
            warning,
            taskId: queryParams.taskId,
            returUrl: queryParams.returURL,
          });
          // dispatch(onReceive(response.Questionnaire, response.Metadata, response.WarningMessage, undefined, isMicroweb));
        } else {
          return rejectWithValue('No response');
        }
      } catch (error) {
        const errorInfo = transformFetchErrors(error);

        return rejectWithValue(errorInfo);
      }
    } else if (params?.Query || skjemaNavnParam) {
      const [loadParamName, loadParamValue] = getLoadParameter(params?.Query || '', skjemaNavnParam);

      const controllerName = isAuthorized() && erTjenester() ? 'api/v1/Skjema' : 'api/v1/SkjemaAnonym';

      try {
        const response = await get<GetSkjemaResponse>('skjemainternal', controllerName, { [loadParamName]: loadParamValue });

        if (response) {
          const { Metadata: metadata, WarningMessage: warning, Questionnaire: data } = response;
          trackFormEvent('form continue', skjemaNavnParam);
          let parsedData: Bundle | Questionnaire;
          try {
            parsedData = JSON.parse(data);
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
          } catch (error) {
            return rejectWithValue('Feil i json struktur');
          }
          const questionnaires = getQuestionnairs(parsedData);

          if (!questionnaires) {
            return rejectWithValue('oppgavenFinsIkke');
          }
          const mainQuestionnaire = handleLanguageAndLoadResources(questionnaires, dispatch);

          // endre url og hent driftsmelding for dette skjema
          if (!isMicroweb) {
            updateUrlAndFetchMessage(mainQuestionnaire);
          }
          const queryParams = getQueryParams();

          const qr = generateQuestionnaireResponseData(undefined, mainQuestionnaire, isMicroweb, skjemadata);
          removeAttachmentsIfNeeded(metadata, qr, mainQuestionnaire);

          dispatch(setSkjemaDefinitionAction({ questionnaire: mainQuestionnaire, questionnaireResponse: qr }));
          return fulfillWithValue({
            questionnaires,
            userMetadata: metadata,
            warning,
            taskId: queryParams?.taskId,
            returUrl: queryParams?.returURL,
          });
        } else {
          return rejectWithValue('Empty response');
        }
      } catch (error) {
        const errorInfo = transformFetchErrors(error);

        return rejectWithValue(errorInfo);
      }
    } else {
      return rejectWithValue('Kunne ikke hente skjema. Mangler nødvendige parametere');
    }
  }
);
export const userChangeLanguage = createAsyncThunk<
  { language: Languages },
  { language: Languages },
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: undefined | TextMessage;
  }
>('skjemautfyller/changeLanguage', async ({ language }, { dispatch, getState, fulfillWithValue }) => {
  await dispatch(remove());

  if (isValidPortalLanguage(language)) {
    window.localStorage.setItem(LocalStorageConstants.Language, language);
    setUserLanguage(language);
  }
  const questionnaires = getState().refero.ui.questionnaires;
  const skjemaDefinition = getQuestionnaireInLanguage(questionnaires, language);
  if (skjemaDefinition) {
    dispatch(loadResources({ language }));
    dispatch(setSkjemaDefinitionAction({ questionnaire: skjemaDefinition }));
  }

  return fulfillWithValue({ language });
});

export const abortSkjema = createAsyncThunk<
  void,
  undefined,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('skjemautfyller/abortSkjema', async (_, { dispatch, getState }) => {
  dispatch(shouldShowBeforeUnload({ show: false }));
  const returUrl = decodeURIComponent(getState().refero.ui.returUrl || '');
  await setAllowNavigation(true);
  if (returUrl) {
    gotoUrl(toRelativeUrl(returUrl));
  } else if (getState().refero.ui.dokumentGuid) {
    redirectTilLagretSkjemaPaaDokumenter();
  } else {
    gotoMinHelse();
  }
});

export const redirectToReturUrlHvisEksisterer = createAsyncThunk<
  void,
  undefined,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: undefined | TextMessage;
  }
>('skjemautfyller/redirectToReturUrlHvisEksisterer', async (_, { dispatch, getState }) => {
  dispatch(shouldShowBeforeUnload({ show: false }));
  const returUrl = decodeURIComponent(getState().refero.ui.returUrl || '');
  if (returUrl) {
    const returnUrlRelative = toRelativeUrl(returUrl);
    gotoUrl(returnUrlRelative);
  } else {
    redirectTilLagretSkjemaPaaDokumenter();
  }
});

export const loadResources = createAsyncThunk<
  SetResourcesPayload | void,
  { language: Languages | string },
  {
    rejectValue: undefined | TextMessage;
    dispatch: AppDispatch;
  }
>('skjemautfyller/loadResources', async ({ language }, { dispatch, rejectWithValue }) => {
  try {
    dispatch(fetchResources(HN_SKJEMA_FRONTEND_RESOURCES, stringToLanguageLocales(language), getResources));
    dispatch(fetchResources(HN_SKJEMA_ERRORCODES_RESOURCES, stringToLanguageLocales(language), getResources));
  } catch (error) {
    return rejectWithValue(error as TextMessage | undefined);
  }
});
