import { FEIL, HN_SKJEMA_FRONTEND_RESOURCES, LOGGETUT_FEILCODE } from 'src/constants/constants';
import { useAppSelector } from 'src/store/reducers';
import { getErrorMessageFromErrorCode } from 'src/store/selectors/errorCodesSelectors';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';
import { HNSkjemaFrontend } from 'src/types/Resources';
import { hentOversettelse } from 'src/util/stringToLanguageLocales';

export const useGetSkjemaErrorTitleFromResources = (resources: HNSkjemaFrontend, error?: string): string => {
  if (!resources) {
    return FEIL;
  }

  return error === LOGGETUT_FEILCODE
    ? hentOversettelse('loggetut_feil_tittel', resources)
    : hentOversettelse('sendInnSkjemaErrorTitle', resources);
};

export const useGetSkjemaErrorBodyFromResources = (resources: HNSkjemaFrontend, errorMessage: string): string => {
  if (!resources) {
    return FEIL;
  }
  if (!errorMessage) {
    return hentOversettelse('sendInnSkjemaErrorBody', resources);
  }

  return errorMessage;
};

export const useGetSkjemaErrorTitleAndBodyFromResources = (error?: string): { errorTitle: string; errorBody: string } => {
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));
  const sendInnSkjemaErrorCode = useAppSelector(state => state.refero.ui.sendInnSkjemaErrorCode);
  const errorMessage = useAppSelector(state => getErrorMessageFromErrorCode(state, sendInnSkjemaErrorCode));
  return {
    errorTitle: useGetSkjemaErrorTitleFromResources(resources, error),
    errorBody: useGetSkjemaErrorBodyFromResources(resources, errorMessage),
  };
};
