import { useEffect } from 'react';

import { useAppSelector } from 'src/store/reducers';

export const useHandleDocumentTitle = (microwebOrStep: boolean | undefined): void => {
  const formTitle = useAppSelector(state => state.refero.form?.FormDefinition?.Content?.title);
  const updateDocumentTitle = (): void => {
    if (formTitle && !microwebOrStep) {
      document.title = formTitle;
    }
  };
  useEffect(() => {
    updateDocumentTitle();
  }, [formTitle]);
};
