import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { getResourcesFromState, ResourceItem } from '@helsenorge/framework-utils/resources';

import { RootState } from '../reducers';

/**
 * A generic selector for retrieving typed resources from the global state.
 *
 * @param state         The root Redux state
 * @param resourceGroup The key/group name for the resource set
 * @param language      Optional language code; defaults to what's in state if not provided
 * @returns             A typed resource object (T) or null
 */
export function getResourcesSelector<T extends ResourceItem = ResourceItem>(
  state: RootState,
  resourceGroup: string,
  language?: LanguageLocales
): T {
  return getResourcesFromState(state, resourceGroup, language?.toString() ?? state.refero.ui.language?.toString()) as T;
}
