import React, { useState, useEffect } from 'react';

import { HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import { useHandleDocumentTitle } from 'src/hooks/useHandleDocumentTitle';
import { useLastActivity } from 'src/hooks/useLastActivity';
import { MicrowebStepFormDataCallBack } from 'src/microweb-step/types/microwebstepTypes';
import { redirectTilInnsendtSkjema } from 'src/store/actions/actionUtils';
import { getLanguagesInDefinition } from 'src/util/getLanguagesInDefinition';
import { getPreferredLanguage } from 'src/util/portalLanguage-utils';

import { trackProsesshjelp } from '@helsenorge/framework-utils/adobe-analytics';
import { getResourcesFromState } from '@helsenorge/framework-utils/resources';
import { SessionTimeoutAction, ShowSignOutBoxData } from '@helsenorge/framework-utils/types/entities';
import { getFormDefinition, getFormData } from '@helsenorge/refero';

import ConfirmationLightbox from './lightboxes/confirmationlightbox';
import ConfirmationLightboxLagre from './lightboxes/confirmationlightbox-lagre';
import SkjemaLoadError from './notifications/skjemaLoadError';
import { fetchSkjemaoppgave, sendInnSkjema, loadResources } from '../store/actions/actionCreators';
import { useAppDispatch, useAppSelector } from '../store/reducers';
import { getQueryParams, isKopiLagret } from '../util';
import RenderSkjema from './RenderSkjema';
import { redirectEtterFullfortSkjema } from '../util/veileder';
import { dispatchSetSessionTimeoutFn } from '../util/webcomp-utils';
import DiscretionLightbox from './lightboxes/discretionLightbox';
import { AnonomousSkjemaLightbox } from './modals/AnonomousSkjemaLightbox';
import AnonymousUploadDeniedModal from './modals/AnonymousUploadDeniedModal';
import AttachementErrorModal from './modals/AttachementErrorModal';
import KanIkkeLagreModal from './modals/KanIkkeLagreModal';
import LagreSkjemaErrorModal from './modals/LagreSkjemaErrorModal';
import SendInnSkjemaErrorModal from './modals/SendInnSkjemaErrorModal';
import LoadSkjemaWarningMessageNotification from './notifications/LoadSkjemaWarningMessageNotification';
import RenderSidebar from './sidebar/RenderSidebar';
import { SkjemaSpinners } from './SkjemaSpinners';

type Props = {
  skjemaTekniskNavn?: string;
  documentGuid?: string;
  isMicroweb?: boolean;
  isMicrowebStep?: boolean;
  avbryturl?: string;
  hidebackbutton?: string;
  skjemadata?: string;
  onNavigateBackMicroweb?: MicrowebStepFormDataCallBack;
  onCompleteMicroweb?: MicrowebStepFormDataCallBack;
  onCancelMicroweb?: MicrowebStepFormDataCallBack;
  sendPendingChangesEvent?: (hasChanges: boolean) => unknown;
};

const Skjemautfyller = ({
  skjemaTekniskNavn,
  documentGuid,
  isMicroweb,
  skjemadata,
  isMicrowebStep,
  avbryturl,
  hidebackbutton,
  onCompleteMicroweb,
  onCancelMicroweb,
  onNavigateBackMicroweb,
  sendPendingChangesEvent,
}: Props): JSX.Element | null => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [isDiscretionLightboxVisible, setIsDiscretionLightboxVisible] = useState(false);
  const [isSentWithDiscretion, setIsSentWithDiscretion] = useState(false);
  const [timeSkjemaLoadedForUser] = useState(Date.now());

  const dispatch = useAppDispatch();
  const uiState = useAppSelector(state => state.refero.ui);
  const formDefinition = useAppSelector(state => getFormDefinition(state));
  const formData = useAppSelector(state => getFormData(state));

  const resources = useAppSelector(state =>
    getResourcesFromState(state, HN_SKJEMA_FRONTEND_RESOURCES, getPreferredLanguage(getLanguagesInDefinition(uiState.questionnaires)))
  );

  const { timeUserHasBeenIdle, setLastActivity, lastActivity } = useLastActivity();
  useHandleDocumentTitle(isMicrowebStep || isMicroweb);

  useEffect(() => {
    dispatch(fetchSkjemaoppgave({ skjemaTekniskNavn, documentGuid, isMicroweb, skjemadata }));
    document.getElementsByTagName('html')[0].classList.add('page_refero__fixed-scroll-offset');
  }, []);

  useEffect(() => {
    dispatch(loadResources({ language: getPreferredLanguage(getLanguagesInDefinition(uiState.questionnaires)) }));

    if (formDefinition?.Content) {
      dispatchSetSessionTimeoutFn(onShowSignOutBox);
    }
  }, [formDefinition?.Content]);

  useEffect(() => {
    updateDocumentTitle(isMicrowebStep || isMicroweb);
  }, [formDefinition]);
  const updateDocumentTitle = (isMicrowebStep: boolean | undefined): void => {
    if (!isMicrowebStep) {
      if (formDefinition && formDefinition.Content && formDefinition.Content.title) {
        document.title = `${formDefinition.Content.title} - Helsenorge`;
      } else {
        document.title = 'Skjemautfyller - Helsenorge';
      }
    }
  };

  const onShowSignOutBox = (data: ShowSignOutBoxData): SessionTimeoutAction => {
    const idleRatio = timeUserHasBeenIdle / (data.tokenLifetime - data.tokenWarnUserWhenRemaining);
    if (idleRatio > 0.5) {
      return SessionTimeoutAction.Default;
    }

    return SessionTimeoutAction.RequestExtension;
  };

  const redirectVeilederData = (normalRedirectFn: () => void): void => {
    const queryParams = getQueryParams();
    redirectEtterFullfortSkjema(
      formDefinition,
      formData,
      queryParams.redirect,
      queryParams.contextParameter,
      '',
      normalRedirectFn,
      isMicrowebStep,
      onCompleteMicroweb
    );
  };

  const handleHideDiscretionLightbox = (): void => {
    setIsDiscretionLightboxVisible(false);
  };

  const toggleSidebarOpen = (isOpen: boolean): void => {
    trackProsesshjelp('Prosesshjelp', 'Hjelp', 'Om skjema', isOpen ? 'Open' : 'Close');
    setIsSidebarOpen(isOpen);
  };

  if (!resources) {
    return null;
  }
  if (uiState.loadSkjemaoppgaveErrorMessage || uiState.loadSkjemaoppgaveErrorCode) {
    return <SkjemaLoadError />;
  }
  return (
    <div>
      <div className="page_refero__content">
        <SkjemaSpinners />
        <LoadSkjemaWarningMessageNotification />
        <RenderSkjema
          pdf={false}
          setLastActivity={setLastActivity}
          timeSkjemaLoadedForUser={timeSkjemaLoadedForUser}
          isMicroweb={isMicroweb}
          isMicrowebStep={isMicrowebStep}
          avbryturl={avbryturl}
          hidebackbutton={hidebackbutton}
          dokumentGuidMicroweb={documentGuid}
          onCancelMicroweb={onCancelMicroweb}
          onCompleteMicroweb={onCompleteMicroweb}
          onNavigateBackMicroweb={onNavigateBackMicroweb}
          sendPendingChangesEvent={sendPendingChangesEvent}
          openSidebar={() => toggleSidebarOpen(true)}
          setIsDiscretionLightboxVisible={setIsDiscretionLightboxVisible}
          lastActivity={lastActivity}
        />
      </div>
      <RenderSidebar isSidebarOpen={isSidebarOpen && !isMicroweb} toggleSidebarOpen={toggleSidebarOpen} />
      <AnonomousSkjemaLightbox />
      <SendInnSkjemaErrorModal />
      <LagreSkjemaErrorModal />
      <KanIkkeLagreModal />
      <ConfirmationLightbox
        onOkButtonClick={(): void => {
          redirectVeilederData(() => redirectTilInnsendtSkjema(uiState.samtaleGuid as string));
        }}
        erKopiLagret={!isSentWithDiscretion && (formDefinition?.Content ? isKopiLagret(formDefinition?.Content) : true)}
      />
      <ConfirmationLightboxLagre />
      <DiscretionLightbox
        isDiscretionLightboxVisible={isDiscretionLightboxVisible}
        closeCallback={handleHideDiscretionLightbox}
        sendCallback={(withDiscretion?: boolean): void => {
          handleHideDiscretionLightbox();
          setIsSentWithDiscretion(withDiscretion || false);
          dispatch(
            sendInnSkjema({
              timeSkjemaLoadedForUser,
              automatiskLagring: false,
              withDiscretion,
              isMicrowebStep,
              onCompleteMicroweb: undefined,
            })
          );
        }}
      />
      <AnonymousUploadDeniedModal />
      <AttachementErrorModal />
    </div>
  );
};

export default Skjemautfyller;
