import { useEffect } from 'react';

import { useAppSelector } from 'src/store/reducers';
import { jsonStringifySortProps } from 'src/util';

import { add, remove } from '@helsenorge/core-framework/pending-changes/pending-changes-state';
import { useAppDispatch } from '@helsenorge/refero';

/**
 ** @param sendPendingChangesEventMicroweb Optional callback ment for microweb/step som videresender hasChanges til konsumenten
 ** @description Hook som lytter til endringer i formen og sender evt. en event til microweb/step hvis callback er satt
 ** @returns boolean som indikerer om det er endringer utført av bruker i skjemaformen
 */
const useFormHasChanges = (sendPendingChangesEventMicroweb?: (hasChanges: boolean) => void): boolean => {
  const shouldShowBeforeUnload = useAppSelector(state => state.refero.ui.shouldShowBeforeUnload);
  const formHasChanges = useAppSelector(state => state.refero.ui.formHasChanges);
  const lastSavedDataItem = useAppSelector(state => state.refero.ui.lastSavedData?.item);
  const item = useAppSelector(state => state.refero.form.FormData.Content?.item);
  const dispatch = useAppDispatch();
  const hasChanges = !!(
    shouldShowBeforeUnload &&
    lastSavedDataItem &&
    item &&
    jsonStringifySortProps(item) !== jsonStringifySortProps(lastSavedDataItem) &&
    formHasChanges === true
  );
  useEffect(() => {
    if (hasChanges) {
      if (sendPendingChangesEventMicroweb) {
        sendPendingChangesEventMicroweb(true);
      }
      dispatch(add());
    } else {
      if (sendPendingChangesEventMicroweb) {
        sendPendingChangesEventMicroweb(false);
      }
      dispatch(remove());
    }
  }, [hasChanges]);

  return hasChanges;
};
export default useFormHasChanges;
