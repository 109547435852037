import React, { useEffect, useState } from 'react';

import { QuestionnaireItem } from 'fhir/r4';
import { DEFAULT_MAX_ATTACHMENT_FILE_SIZE_HN_SKJEMA_BYTES, HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import { SaveCapabilites } from 'src/constants/saveCapabilites';
import { TopMessageVisibility } from 'src/constants/topMessageVisibility';
import { useAttachmentUtils } from 'src/hooks/useAttachmentUtils';
import useFormHasChanges from 'src/hooks/useFormHasChanges';
import { useGetSchemaResources } from 'src/hooks/useGetSchemaResources';
import { useHandleAuthenticationRequirment } from 'src/hooks/useHandleAuthenticationRequirment';
import { MicrowebStepFormDataCallBack, MicrowebStepReferoButtonCallBackDirection } from 'src/microweb-step/types/microwebstepTypes';
import { lagreSkjema, lastMottakere, loadValueSet, sendInnSkjema, abortSkjema } from 'src/store/actions/actionCreators';
import { shouldShowBeforeUnload as shouldShowBeforeUnloadFn } from 'src/store/actions/actions';
import { gotoMinHelse } from 'src/store/actions/actionUtils';
import { useAppDispatch, useAppSelector } from 'src/store/reducers';
import {
  formHasChanges,
  kanIkkeLagreError,
  promptLoginMessage,
  setSamtaleguid,
  showAttachmentMaxFilesError,
} from 'src/store/reducers/uiSlice';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';
import { HNSkjemaFrontend } from 'src/types/Resources';
import { getQueryParams, getSkjemaNameFromUrl, hasTooManyAttachments } from 'src/util';
import { getLastSaveCapabilitiesValue } from 'src/util/extension';
import { trackFormEvent } from 'src/util/trackFormEvent';
import { redirectEtterFullfortSkjema } from 'src/util/veileder';

import { addBlock, remove } from '@helsenorge/core-framework/pending-changes/pending-changes-state';
import { Refero, OrgenhetHierarki } from '@helsenorge/refero';

import EventListener from './eventListener';
import HelpButton from './helpComponents/HelpButton';
import HelpElement from './helpComponents/HelpElement';
import LanguageSelector from './languageselector';
import Navigator from './navigator/navigator';
import SkjemaLoginButton from './skjemaLoginButton';
import SkjemaTitle from './SkjemaTitle';
import StepViewComponent from './stepView/StepViewComponent';
import { shouldFormBeDisplayedAsStepView } from './stepView/utils';
import TopMessage from './TopMessage/TopMessageComponent';

type Props = {
  pdf?: boolean;
  isMicrowebStep?: boolean;
  isMicroweb?: boolean;
  hidebackbutton?: string;
  avbryturl?: string;
  dokumentGuidMicroweb?: string;
  onNavigateBackMicroweb?: MicrowebStepFormDataCallBack;
  onCompleteMicroweb?: MicrowebStepFormDataCallBack;
  onCancelMicroweb?: MicrowebStepFormDataCallBack;
  sendPendingChangesEvent?: (hasChanges: boolean) => void;
  setIsDiscretionLightboxVisible: React.Dispatch<React.SetStateAction<boolean>>;
  timeSkjemaLoadedForUser: number;
  setLastActivity: React.Dispatch<React.SetStateAction<number>>;
  openSidebar: () => void;
  lastActivity: number;
};

const RenderSkjema = ({
  pdf,
  isMicrowebStep,
  isMicroweb,
  hidebackbutton,
  avbryturl,
  dokumentGuidMicroweb,
  onCancelMicroweb,
  onNavigateBackMicroweb,
  onCompleteMicroweb,
  sendPendingChangesEvent,
  setIsDiscretionLightboxVisible,
  timeSkjemaLoadedForUser,
  setLastActivity,
  openSidebar,
  lastActivity,
}: Props): React.JSX.Element | null => {
  const dispatch = useAppDispatch();
  const [stepIndex, setStepIndex] = useState(0);

  const uiState = useAppSelector(state => state.refero.ui);
  const formData = useAppSelector(state => state.refero.form.FormData);
  const formDefinition = useAppSelector(state => state.refero.form.FormDefinition);
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));
  const kanMellomlagres = useAppSelector(state => state.refero.ui.kanMellomlagres);
  const userMetadata = useAppSelector(state => state.refero.ui.userMetadata);
  const blockSubmit = useAppSelector(state => state.refero.ui.blockSubmit);
  const [originalQr, setOriginalQr] = useState<string | undefined>(undefined);
  const { getAttachmentLink, openAttachment, uploadAttachment, deleteAttachment } = useAttachmentUtils();
  const hasChanges = useFormHasChanges(sendPendingChangesEvent);
  const skjemaResources = useGetSchemaResources(isMicrowebStep);
  const { authenticationRequirement, isAuthorized, allowAnonymousOrAuthorized } = useHandleAuthenticationRequirment();
  useEffect(() => {
    if (formDefinition.Content) {
      setOriginalQr(JSON.stringify(formDefinition.Content));
    }
  }, [formDefinition.Content]);
  if (!formDefinition || !formDefinition.Content || !resources) {
    return null;
  }
  const redirectVeilederData = (normalRedirectFn: () => void): void => {
    const queryParams = getQueryParams();
    redirectEtterFullfortSkjema(
      formDefinition,
      formData,
      queryParams.redirect,
      queryParams.contextParameter,
      '',
      normalRedirectFn,
      isMicrowebStep,
      onCompleteMicroweb
    );
  };

  const onCustomNavigationCallBack = (direction: string): void => {
    switch (direction) {
      case MicrowebStepReferoButtonCallBackDirection.Cancel:
        if (onCancelMicroweb) {
          onCancelMicroweb({
            undefined,
          });
        }
        break;
      case MicrowebStepReferoButtonCallBackDirection.Forward:
        if (onCompleteMicroweb) {
          onCompleteMicroweb({
            dokumentGuid: dokumentGuidMicroweb,
            skjemadata: JSON.stringify(formData),
          });
        }
        break;
      case MicrowebStepReferoButtonCallBackDirection.Back:
        if (onNavigateBackMicroweb) {
          onNavigateBackMicroweb({
            dokumentGuid: dokumentGuidMicroweb,
            skjemadata: JSON.stringify(formData),
          });
        }
        break;
      default:
        break;
    }
  };

  const submitForm = (): void => {
    const formName = formDefinition?.Content?.name ? formDefinition?.Content?.name : 'Skjema uten navn';

    const lastSaveCapabilityItemValue = getLastSaveCapabilitiesValue(formDefinition?.Content?.item, formData?.Content?.item);
    const itemApprovesSavingOnComplete =
      lastSaveCapabilityItemValue === SaveCapabilites.FinalAndIntermediateVersion ||
      lastSaveCapabilityItemValue === SaveCapabilites.FinalVersionOnly;

    if (lastSaveCapabilityItemValue === SaveCapabilites.NoSaving || (!itemApprovesSavingOnComplete && !uiState.kanLagresEtterInnsending)) {
      dispatch(setSamtaleguid({ samtaleGuid: uiState.samtaleGuid }));

      dispatch(shouldShowBeforeUnloadFn({ show: false }));
      redirectVeilederData(gotoMinHelse);
    } else if (formName === 'HV-KGBS-1') {
      setIsDiscretionLightboxVisible(true);
    } else {
      dispatch(
        sendInnSkjema({
          timeSkjemaLoadedForUser,
          automatiskLagring: false,
          withDiscretion: undefined,
          isMicrowebStep,
          onCompleteMicroweb,
          dokumentGuidMicroweb,
        })
      );
    }
  };
  const onSubmit = (): void => {
    const waitForPending = async (): Promise<void> => {
      await dispatch(remove());
      if (hasTooManyAttachments(formData?.Content)) {
        dispatch(showAttachmentMaxFilesError());
      } else {
        if (!uiState.skjemaSendesInn) {
          submitForm();
        }
      }
    };
    waitForPending();
  };
  const onSave = (): void | undefined => {
    const lastSaveCapabilityItemValue = getLastSaveCapabilitiesValue(formDefinition?.Content?.item, formData?.Content?.item);

    if (lastSaveCapabilityItemValue === SaveCapabilites.NoSaving || lastSaveCapabilityItemValue === SaveCapabilites.FinalVersionOnly) {
      dispatch(kanIkkeLagreError());
    } else if (hasTooManyAttachments(formData?.Content)) {
      dispatch(showAttachmentMaxFilesError());
    } else {
      dispatch(lagreSkjema({ timeSkjemaLoadedForUser, automatiskLagring: false }));
    }
  };

  const onCancel = (): void => {
    if (onCancelMicroweb) {
      if (formData) {
        onCancelMicroweb({ undefined });
      }
    } else if (hasChanges) {
      dispatch(addBlock());
    } else {
      dispatch(remove());
      dispatch(abortSkjema());
    }
  };

  const registerActivity = (): void => {
    setLastActivity(Date.now());
    if (originalQr) {
      if (originalQr !== JSON.stringify(formData.Content)) {
        dispatch(formHasChanges(true));
      } else {
        dispatch(formHasChanges(false));
      }
    }
  };

  const getSaveFn = (): (() => void) | undefined => {
    return kanMellomlagres ? onSave : undefined;
  };

  const erSendOgLagreKnapperDisabled = (): boolean => {
    const hideIfRepresentertSubjectOnly = userMetadata?.IsRepresentert && userMetadata?.CanOnlyBePerformedBySubject;
    const hideIfNoTilgang = !userMetadata?.HasTilgang;
    return isAuthorized() && (hideIfRepresentertSubjectOnly || hideIfNoTilgang);
  };

  const trackFormError = (): void => {
    trackFormEvent('site error', getSkjemaNameFromUrl());
  };

  if (pdf) {
    return (
      <div>
        <SkjemaTitle
          openSidebar={openSidebar}
          formDefinition={formDefinition}
          isMicroweb={isMicroweb}
          resources={resources}
          sidebarVisibility={uiState.sidebarVisibility}
        />
        <Refero
          pdf={true}
          authorized={allowAnonymousOrAuthorized}
          blockSubmit={blockSubmit}
          onCancel={onCancel}
          onSave={getSaveFn()}
          onSubmit={onSubmit}
          loginButton={<SkjemaLoginButton buttonText={resources.skjemaLoginButton} />}
          resources={skjemaResources}
        />
      </div>
    );
  }

  const determineTopMessageVisibility = (): number | undefined => {
    const shouldHideTopMessage = formDefinition && shouldFormBeDisplayedAsStepView(formDefinition) && stepIndex && stepIndex > 0;
    return shouldHideTopMessage ? TopMessageVisibility.Off : uiState.topMessageVisibility;
  };
  const submitButtonDisabled = !!uiState.loadSkjemaoppgaveWarningMessage || erSendOgLagreKnapperDisabled();

  const fetchReceivers = (successCallback: (receivers: Array<OrgenhetHierarki>) => void, errorCallback: () => void): void => {
    lastMottakere(formDefinition.Content?.name || '', successCallback, errorCallback);
  };
  const promptLoginMessageFn = (): void => {
    dispatch(
      promptLoginMessage({
        questionnaire: formDefinition?.Content ?? undefined,
      })
    );
  };
  return (
    <div>
      <EventListener timeSkjemaLoadedForUser={timeSkjemaLoadedForUser} automatiskLagring={true} kanMellomlagres={kanMellomlagres} />
      {!isMicrowebStep && <LanguageSelector />}
      <StepViewComponent stepIndex={stepIndex} isMicroweb={isMicroweb} />
      <SkjemaTitle
        isMicroweb={isMicroweb}
        openSidebar={openSidebar}
        formDefinition={formDefinition}
        resources={resources}
        sidebarVisibility={uiState.sidebarVisibility}
      />
      {userMetadata && (
        <TopMessage
          resources={resources}
          authenticationRequirement={authenticationRequirement}
          userMetadata={userMetadata}
          printReference={uiState.printReference}
          openSidebar={openSidebar}
          visibility={determineTopMessageVisibility()}
        />
      )}
      <Navigator lastActivity={lastActivity} />
      <Refero
        authorized={allowAnonymousOrAuthorized}
        blockSubmit={blockSubmit}
        onCancel={onCancel}
        onSave={getSaveFn()}
        onSubmit={onSubmit}
        loginButton={<SkjemaLoginButton doNotRender={isMicroweb} buttonText={resources.skjemaLoginButton} />}
        resources={skjemaResources}
        promptLoginMessage={promptLoginMessageFn}
        onRequestAttachmentLink={getAttachmentLink}
        onOpenAttachment={openAttachment}
        onDeleteAttachment={deleteAttachment}
        uploadAttachment={uploadAttachment}
        attachmentErrorMessage={hasTooManyAttachments(formData?.Content) ? resources.attachment_field_max_error : undefined}
        sticky={isMicroweb ? false : true}
        onRequestHelpButton={
          isMicroweb
            ? undefined
            : (
                _item: QuestionnaireItem,
                _itemHelp: QuestionnaireItem,
                _helpType: string,
                _helpText: string,
                opening: boolean
              ): React.JSX.Element => <HelpButton resources={resources} opening={opening} />
        }
        onRequestHelpElement={
          isMicroweb
            ? undefined
            : (
                _item: QuestionnaireItem,
                _itemHelp: QuestionnaireItem,
                _helpType: string,
                helpText: string,
                opening: boolean
              ): React.JSX.Element => <HelpElement helpText={helpText} opening={opening} />
        }
        validateScriptInjection
        onChange={registerActivity}
        fetchValueSet={loadValueSet}
        attachmentMaxFileSize={DEFAULT_MAX_ATTACHMENT_FILE_SIZE_HN_SKJEMA_BYTES}
        autoSuggestProps={{
          minSearchCharacters: 3,
          typingSearchDelay: 500,
        }}
        submitButtonDisabled={submitButtonDisabled}
        saveButtonDisabled={erSendOgLagreKnapperDisabled()}
        fetchReceivers={fetchReceivers}
        onFieldsNotCorrectlyFilledOut={trackFormError}
        onStepChange={setStepIndex}
        customProps={{
          isMicrowebStep: isMicrowebStep,
          isMicroweb: isMicroweb,
          cancelUrl: avbryturl,
          hideBackButton: hidebackbutton,
        }}
        customNavigationCallBack={onCustomNavigationCallBack}
      />
    </div>
  );
};
export default RenderSkjema;
